import PropTypes from "prop-types";
import React from "react"; // eslint-disable-line import/no-extraneous-dependencies
import Pager from "stelar/components/Pager";
import { handlePageLink } from "shared/utils/routing";

const handleClick = (e) => {
  e.preventDefault();
  const path = e.currentTarget.getAttribute("href");
  const parameters = [];
  const url = path.substring(path.indexOf("?") + 1).split("&");

  url
    .filter((item) => item.includes("sortby="))
    .map((search) => parameters.push(search));
  url
    .filter((item) => item.includes("p="))
    .map((search) => parameters.push(search));
  url
    .filter((item) => item.includes("q="))
    .map((search) => parameters.push(search));
  url
    .filter((item) => item.includes("category="))
    .map((search) => parameters.push(search));

  handlePageLink({
    link: path.indexOf("?") > 0 ? path.substring(0, path.indexOf("?")) : path,
    parameters,
  });
};

function ProductPager({ pager, type }) {
  return (
    <Pager {...pager} onClick={handleClick} type={type} data-test-sell-pager />
  );
}

ProductPager.propTypes = {
  pager: PropTypes.object,
  type: PropTypes.string,
};

export default ProductPager;
