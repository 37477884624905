import React from "react"; // eslint-disable-line import/no-extraneous-dependencies
import PropTypes from "prop-types";
import classnames from "classnames/bind";
import IconBoldDown from "stelar/components/Icon/IconBoldDown";
import IconBoldUp from "stelar/components/Icon/IconBoldUp";
import s from "./SelectButton.module.css";
import Bubble from "../Bubble";

const cx = classnames.bind(s);

export default function SelectButton({
  className,
  children,
  count,
  heading,
  name,
  onClick,
  open = false,
  ...props
}) {
  return (
    <button
      className={cx("root", className, { open })}
      data-test-sell-select-button={name}
      onClick={onClick}
      type="button"
      {...props}
    >
      {heading}
      {count && count !== 0 ? (
        <Bubble count={count} data-test-sell-facet-filter-bubble />
      ) : null}
      {open ? <IconBoldUp small /> : <IconBoldDown small />}
    </button>
  );
}

SelectButton.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  count: PropTypes.number,
  heading: PropTypes.string,
  name: PropTypes.string,
  onClick: PropTypes.func,
  open: PropTypes.bool,
  showIcon: PropTypes.bool,
};
