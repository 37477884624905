import React from "react"; // eslint-disable-line import/no-extraneous-dependencies
import PropTypes from "prop-types";
import classnames from "classnames/bind";
import Text from "stelar/components/Text";
import s from "./Bubble.module.css";

const cx = classnames.bind(s);

function Bubble({ count, inline, line, ...props }) {
  if (!count || count <= 0) return null;
  return (
    <div className={cx("root", { inline, line, upper: !inline })} {...props}>
      <div className={s.bubble}>
        <div className={s.bubbleText}>
          <Text inverted small>
            {count}
          </Text>
        </div>
      </div>
    </div>
  );
}

Bubble.propTypes = {
  count: PropTypes.number,
  inline: PropTypes.bool,
  line: PropTypes.bool,
};

export default Bubble;
