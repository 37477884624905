/* eslint-disable jsx-a11y/no-static-element-interactions, jsx-a11y/click-events-have-key-events */
import PropTypes from "prop-types";
import React, { useEffect, useLayoutEffect, useRef } from "react"; // eslint-disable-line import/no-extraneous-dependencies
import { v4 as uuidv4 } from "uuid";
import DrawerContent from "./DrawerContent";
import s from "./BottomDrawer.module.css";

/**
 * Can be used as an alternative to native dropdowns for mobile.
 */
function BottomDrawer({ lists, parent, opened, onClose, closeButtonText }) {
  const drawerId = useRef(uuidv4());

  const handleEscKey = (e) => {
    if (e.keyCode === 27) {
      onClose();
    }
  };

  useEffect(() => {
    window.document.addEventListener("keydown", handleEscKey);
    return () => window.document.removeEventListener("keydown", handleEscKey);
  }, []);

  useLayoutEffect(() => {
    if (typeof window !== "undefined" && typeof document !== "undefined") {
      if (opened) {
        const scrollBarWidth = window.innerWidth - document.body.offsetWidth;
        document.body.style.margin = `0px ${scrollBarWidth}px 0px 0px`;
        document.body.style.overflow = "hidden";
      } else {
        document.body.style.margin = "";
        document.body.style.overflow = "";
      }
    }
  }, [opened]);

  const overlayContent = (
    <DrawerContent
      drawerId={drawerId.current}
      opened={opened}
      lists={lists}
      parent={parent}
      hideOverlay={onClose}
      closeButtonText={closeButtonText}
    />
  );

  return (
    <>
      <div
        aria-hidden={!opened}
        className={s.smokedGlass}
        onClick={onClose}
        data-test-stelar-overlay-background
      />
      <div aria-hidden={!opened} className={s.overlayContent} onClick={onClose}>
        <div
          aria-hidden={!opened}
          className={s.overlayWrapper}
          tabIndex="-1"
          aria-labelledby={`drawerTitle-${drawerId.current}`}
          data-test-sell-drawer
        >
          {overlayContent}
        </div>
      </div>
    </>
  );
}

BottomDrawer.propTypes = {
  lists: PropTypes.object.isRequired,
  parent: PropTypes.object,
  opened: PropTypes.bool.isRequired,
  onClose: PropTypes.func,
  closeButtonText: PropTypes.string,
};

export default BottomDrawer;
