import React from "react"; // eslint-disable-line import/no-extraneous-dependencies
import SortSelect from "./SortSelect";

function SkeletonSortSelect() {
  return (
    <SortSelect
      skeleton
      order={[{ name: "Beliebteste Artikel" }]}
      label="Sortierung"
    />
  );
}

export default SkeletonSortSelect;
