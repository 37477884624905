import React from "react";
import classNames from "classnames/bind";

import Text from "../Text";
import s from "./Breadcrumb.module.css";
import IconRight from "../Icon/IconRight";
import IconLeft from "../Icon/IconLeft";

const cx = classNames.bind(s);

function generateRel(depth, current) {
  if (current === 0) {
    return "index";
  }
  return "up ".repeat(depth + 1).trim();
}

type Crumb = {
  name?: string;
  link?: string;
  optional?: boolean;
};

type Props = {
  back?: string;
  backLink?: string;
  crumbs?: Crumb[];
  endpoint?: string;
  productCount?: string;
  onClickLink?: (...args: unknown[]) => unknown;
  onClickBack?: (...args: unknown[]) => unknown;
  skeleton?: boolean;
};

function Breadcrumb({
  back,
  backLink,
  crumbs = [],
  endpoint,
  onClickBack,
  onClickLink,
  productCount,
  skeleton,
}: Props) {
  function createCrumbs() {
    return crumbs.map(({ name, link, optional }, index) => (
      <li className={cx("crumb", { optional })} key={name}>
        <Text small>
          <span className={s.crumbTextContainer}>
            {index !== 0 && (
              <span className={s.iconContainer}>
                <IconRight tiny />
              </span>
            )}
            <a
              className={cx({ skeleton })}
              data-prio2
              href={link}
              onClick={onClickLink}
              rel={generateRel(crumbs.length - 1 - index, index)}
            >
              {name}
            </a>
            {(crumbs.length === 1 || index === crumbs.length - 1) &&
              endpoint && (
                <span className={cx("iconContainer", "lastArrow")}>
                  <IconRight tiny />
                </span>
              )}
          </span>
        </Text>
      </li>
    ));
  }

  function createBackLink() {
    if (!back) {
      return null;
    }
    return (
      <li className={cx("crumb", "back")}>
        <Text small>
          <span className={s.crumbTextContainer}>
            <span className={s.iconContainer}>
              <IconLeft tiny />
            </span>
            <a
              className={cx({ skeleton })}
              data-prio2
              href={backLink}
              onClick={onClickBack}
              rel="prev"
              tabIndex={0}
            >
              {back}
            </a>
          </span>
        </Text>
      </li>
    );
  }

  return (
    <div className={s.root} role="navigation">
      <ul className={s.crumblist}>
        {createBackLink()}
        {createCrumbs()}
        {endpoint ? (
          <li className={cx("crumb", "last")}>
            <Text
              small
              tag="h1"
              skeleton={skeleton}
              className={cx("endpoint", { skeleton })}
            >
              {endpoint}
            </Text>
            <Text small>
              <span className={cx("productCount", { skeleton })}>
                {productCount}
              </span>
            </Text>
          </li>
        ) : null}
      </ul>
    </div>
  );
}

export default Breadcrumb;
