/* eslint-disable max-len */
import React from "react";
import { classNames, IconType } from "./common";

function IconBoldRight(props: IconType) {
  const { alt } = props;
  return (
    <svg className={classNames(props)} viewBox="0 0 12 8" aria-label={alt}>
      {alt ? <title>{alt}</title> : null}
      <path d="M0.8,7.2c0.4,0.4,1,0.4,1.4,0l3.8-4l3.8,4c0.4,0.4,1.1,0.4,1.4,0c0.4-0.4,0.4-1.1,0-1.6L6.7,0.8 C6.5,0.6,6.3,0.5,6,0.5c-0.3,0-0.5,0.1-0.7,0.3L0.8,5.6C0.4,6,0.4,6.7,0.8,7.2z" />
    </svg>
  );
}

export default IconBoldRight;
