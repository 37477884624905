import PropTypes from "prop-types";
/* eslint-disable react/no-array-index-key */
import LabeledSelect from "stelar/components/LabeledSelect";
import { breakpoint } from "stelar/components/media";
import React from "react"; // eslint-disable-line import/no-extraneous-dependencies
import {
  buildUrlWithParams,
  getParameters,
  handleSelectWithLinklist,
} from "shared/utils/routing";
import useMediaQuery from "shared/utils/useMediaQuery";
import BottomDrawer from "../BottomDrawer";
import s from "./SortSelect.module.css";

function SortSelect({ label, options, selected, skeleton, id }) {
  const [drawerOpen, setDrawerOpen] = React.useState(false);
  const isSmartphoneBreakpoint = useMediaQuery(
    `(max-width: ${breakpoint.s.max}px)`,
    false
  );
  const isDrawerEnabled = isSmartphoneBreakpoint;

  const lists = [
    {
      title: label,
      naviElements:
        options?.map((option) => ({
          name: option.name,
          link: buildUrlWithParams(option, getParameters(option)),
        })) || [],
    },
  ];

  const onOpenDrawer = (e) => {
    e.preventDefault();
    setDrawerOpen(true);
  };

  const navigationDrawerHandler = isDrawerEnabled && {
    onClick: onOpenDrawer,
    onMouseDown: (e) => e.preventDefault(),
    onKeyDown: (e) => e.key === "Enter" && onOpenDrawer(e),
  };

  return (
    <div className={s.root}>
      {selected ? (
        <>
          <LabeledSelect
            id={id}
            onChange={(e) => handleSelectWithLinklist(e, options)}
            value={selected}
            label={label}
            skeleton={skeleton}
            hideLabelOnSmallScreen
            data-test-sell-product-sort
            {...navigationDrawerHandler}
          >
            {options &&
              options.map(({ name }, index) => (
                <option value={name} key={index}>
                  {name}
                </option>
              ))}
          </LabeledSelect>
          <BottomDrawer
            lists={lists}
            opened={drawerOpen}
            onClose={() => setDrawerOpen(false)}
          />
        </>
      ) : null}
    </div>
  );
}

SortSelect.propTypes = {
  label: PropTypes.string,
  options: PropTypes.array,
  selected: PropTypes.string,
  id: PropTypes.string,
  skeleton: PropTypes.bool,
};

export default SortSelect;
