// vendor
import React from "react"; // eslint-disable-line import/no-extraneous-dependencies
import PropTypes from "prop-types";
// stelar
import { AccordionButton } from "stelar/components/Accordion";
import IconDown from "stelar/components/Icon/IconDown";
import IconUp from "stelar/components/Icon/IconUp";
// components
import Bubble from "../../Bubble";
// styling
import s from "./Accordion.module.css";

function FacetAccordionButton({
  heading,
  initialDisplay,
  onToggleListDisplay,
  selectedCountSum,
}) {
  return (
    <AccordionButton
      open
      light
      iconPosition="right"
      icon={!initialDisplay ? <IconDown small /> : <IconUp small />}
      title={
        <div className={s.filterTitle}>
          {heading}
          {!initialDisplay && (
            <Bubble
              count={selectedCountSum}
              data-test-sell-facet-filter-bubble-sum
              line
            />
          )}
        </div>
      }
      onClick={onToggleListDisplay}
    />
  );
}

FacetAccordionButton.propTypes = {
  heading: PropTypes.string,
  initialDisplay: PropTypes.bool,
  onToggleListDisplay: PropTypes.func,
  selectedCountSum: PropTypes.number,
};

export default FacetAccordionButton;
