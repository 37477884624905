import React from "react";

import Breadcrumb from "./Breadcrumb";

type Props = {
  reduced?: boolean;
};

function SkeletonBreadcrumb({ reduced }: Props) {
  return (
    <Breadcrumb
      back={reduced ? null : "Zurück"}
      crumbs={[
        { name: "Startseite", link: "/" },
        { name: "Herren", link: "/herren/" },
      ]}
      endpoint="Shirts &amp; Hemden"
      skeleton
    />
  );
}

export default SkeletonBreadcrumb;
