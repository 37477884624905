import classnames from "classnames/bind";

import React from "react";
import Select from "../Select";
import Text from "../Text";
import s from "./LabeledSelect.module.css";

const cx = classnames.bind(s);

type Props = {
  dateMonth?: boolean;
  error?: string;
  id?: string;
  selectOptions?: unknown[];
  children?: React.ReactNode;
  skeleton?: boolean;
  label?: string;
  hideLabelOnSmallScreen?: boolean;
} & React.ComponentPropsWithoutRef<typeof Select>;

function LabeledSelect({
  label,
  hideLabelOnSmallScreen,
  ...selectProps
}: Props) {
  return (
    <div className={s.root}>
      {label ? (
        <label
          htmlFor={selectProps.id}
          className={cx("label", { hideLabelOnSmallScreen })}
        >
          <Text light skeleton={selectProps.skeleton}>
            {label}
          </Text>
        </label>
      ) : null}
      <div className={s.selectWrapper}>
        <Select {...selectProps} />
      </div>
    </div>
  );
}

export default LabeledSelect;
