import React from "react";
import classnames from "classnames/bind";
import IconBoldLeft from "../Icon/IconBoldLeft";
import IconBoldRight from "../Icon/IconBoldRight";

import s from "./Pager.module.css";

const cx = classnames.bind(s);

type Props = {
  current: string;
  total: string;
  prevUrl?: string;
  prevText?: string;
  nextUrl?: string;
  nextText?: string;
  ofText?: string;
  onClick?: (...args: unknown[]) => unknown;
  pages?: unknown[];
  skeleton?: boolean;
  type?: "reduced" | "detailed";
};

function Pager({
  current,
  total,
  prevUrl,
  prevText,
  nextUrl,
  nextText,
  ofText,
  onClick,
  pages,
  type,
  skeleton,
  ...attrs
}: Props) {
  const countText = `${current} ${ofText} ${total}`;
  function getButton(url, text, direction) {
    const testAttribute = {};
    testAttribute[`data-test-stelar-pager-${direction}`] = true;

    return url ? (
      <a
        href={url}
        className={s[direction]}
        onClick={onClick}
        {...testAttribute}
      >
        {direction === "prev" ? (
          <span className={s.icon}>
            <IconBoldLeft tiny />
          </span>
        ) : null}
        {type === "detailed" ? (
          <span className={s.buttonText}>{text}</span>
        ) : null}
        {direction === "next" ? (
          <span className={s.icon}>
            <IconBoldRight tiny />
          </span>
        ) : null}
      </a>
    ) : null;
  }
  return (
    <div {...attrs} className={s.root}>
      {getButton(prevUrl, prevText, prevUrl ? "prev" : false)}
      <span className={cx("text", { skeleton })}>
        {type === "detailed" && pages
          ? pages.map(({ dots, number, url }, index) => {
              const tag = url ? "a" : "span";
              const props = url
                ? {
                    href: url,
                    onClick,
                    key: index,
                    className: cx("element", "link"),
                  }
                : {
                    key: index,
                    className: cx("element", {
                      dots,
                      active: !dots,
                    }),
                  };
              return React.createElement(tag, props, dots ? "…" : `${number}`);
            })
          : countText}
      </span>
      {getButton(nextUrl, nextText, nextUrl ? "next" : false)}
    </div>
  );
}

Pager.defaultProps = {
  ofText: "von",
  type: "reduced",
};

export default Pager;
