/* eslint-disable max-len */
import React from "react";
import { classNames, IconType } from "./common";

function IconBoldLeft(props: IconType) {
  const { alt } = props;
  return (
    <svg className={classNames(props)} viewBox="0 0 8 12" aria-label={alt}>
      {alt ? <title>{alt}</title> : null}
      <path d="M7.2,11.2c0.4-0.4,0.4-1,0-1.4L3.2,6l4-3.8c0.4-0.4,0.4-1.1,0-1.4c-0.4-0.4-1.1-0.4-1.6,0L0.8,5.3 C0.6,5.5,0.5,5.7,0.5,6s0.1,0.5,0.3,0.7l4.8,4.5C6,11.6,6.7,11.6,7.2,11.2z" />
    </svg>
  );
}

export default IconBoldLeft;
