import React from "react"; // eslint-disable-line import/no-extraneous-dependencies
import PropTypes from "prop-types";
import classNames from "classnames/bind";
import Text from "stelar/components/Text";
import s from "./FacetTextList.module.css";

const cx = classNames.bind(s);

function FacetTextList({ facetName, values, onChange }) {
  return (
    values && (
      <div>
        <div id={`facetTextListId-${facetName}`} className={cx("filters")}>
          <ul className={s.ul}>
            {values.map((element) => (
              <li
                data-test-sell-facet-filter-facets
                key={`${facetName}-${element.value}`}
                className={cx(s.filter, {
                  activeFilter: element.status === "selected",
                })}
              >
                <input
                  type="checkbox"
                  id={`${facetName}-${element.value}`}
                  key={`${facetName}-${element.value}-${new Date().getTime()}`}
                  name={element.value}
                  value={element.value}
                  defaultChecked={element.status === "selected"}
                  disabled={element.status === "inactive"}
                  onChange={onChange}
                  data-facet-filter={facetName}
                  data-facet-value={element.value}
                  className={s.checkbox}
                />
                <label
                  htmlFor={`${facetName}-${element.value}`}
                  className={cx("labelElement", {
                    disabled: element.status === "inactive",
                  })}
                >
                  <span
                    className={cx("label", {
                      disabled: element.status === "inactive",
                    })}
                  >
                    <Text small>{element.value}</Text>
                  </span>
                </label>
              </li>
            ))}
          </ul>
        </div>
      </div>
    )
  );
}

FacetTextList.propTypes = {
  facetName: PropTypes.string,
  values: PropTypes.array,
  onChange: PropTypes.func,
};

export default FacetTextList;
