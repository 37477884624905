// vendor
import classNames from "classnames/bind";
import PropTypes from "prop-types";
import React from "react"; // eslint-disable-line import/no-extraneous-dependencies
import { buildUrl, handleLink } from "shared/utils/routing";
import {
  resetScrollPositionInFacetFilter,
  scrollDocument,
} from "shared/utils/scrollTo";
// stelar
import { Button } from "stelar/components/Button";
import IconX from "stelar/components/Icon/IconX";
import LinkButton from "stelar/components/LinkButton";
// components
import SelectButton from "../SelectButton/SelectButton";
import FacetAccordion, { FacetAccordionButton } from "./FacetAccordion";

// styling
import "core-js/actual/array";
import "core-js/actual/set";
import "url-search-params-polyfill";
import s from "./FacetFilter.module.css";

const cx = classNames.bind(s);

function FacetFilter({
  facetFilters: {
    facets,
    query,
    stp,
    sortby,
    buttonText,
    resetButtonText,
    heading,
    selectedCountSum,
  },
  url,
  initialDisplay = true,
  toggleListDisplay,
  closeOrOpenFacetFilter,
  closedFacetFilters,
  ...attrs
}) {
  const formLocation = url || "/suche/";
  const onToggleFilter = (event, changedFacet, facetLists) => {
    const parameters = [
      ...(query ? [`q=${query}`] : []),
      ...(sortby ? [`sortby=${sortby}`] : []),
      ...(stp ? [`stp=${stp}`] : []),
    ];
    Array.from(
      new Set(
        new URLSearchParams(window.location.search)
          .getAll("sortby")
          .map((x) => (typeof x === "string" ? x.toLowerCase() : x))
      )
    ).map((search) => parameters.push(`sortby=${search}`));

    facetLists.map((facet) =>
      facet.values.forEach((value) => {
        if (value.status === "selected" && value.value !== event.target.value) {
          parameters.push(`${facet.name}=${value.url}`);
        }
        if (event.target.checked && value.value === event.target.value) {
          parameters.push(`${changedFacet.name}=${value.url}`);
        }
      })
    );

    scrollDocument();
    handleLink({
      link: formLocation,
      parameters,
    });
  };

  const onToggleListDisplay = (event) => {
    event.preventDefault();
    toggleListDisplay();
    scrollDocument();
  };

  const resetFilters = () => {
    const parameters = [`q=${query}`];
    if (sortby) {
      parameters.push(`sortby=${sortby}`);
    }
    if (stp) {
      parameters.push(`stp=${stp}`);
    }
    scrollDocument();
    handleLink({
      link: formLocation,
      parameters,
    });
  };

  const onClickFacetName = (facetName, event) => {
    event.preventDefault();
    closeOrOpenFacetFilter(facetName);
    resetScrollPositionInFacetFilter(facetName);
  };

  const onFormSubmit = (event) => {
    event.preventDefault();
    const parameters = [
      ...(query ? [`q=${query}`] : []),
      ...(sortby ? [`sortby=${sortby}`] : []),
      ...(stp ? [`stp=${stp}`] : []),
    ];
    facets.map((facet) =>
      facet.values.forEach((value) => {
        if (value.status === "selected" && value.value !== event.target.value) {
          parameters.push(`${facet.name}=${value.url}`);
        }
      })
    );
    window.location = buildUrl({
      link: formLocation,
      parameters,
    });
  };

  const facetAccordionButton = (
    <div
      className={cx("heading", "large", {
        open: initialDisplay,
        closed: !initialDisplay,
      })}
    >
      <FacetAccordionButton
        heading={heading}
        initialDisplay={initialDisplay}
        onToggleListDisplay={onToggleListDisplay}
        selectedCountSum={selectedCountSum}
      />
    </div>
  );

  return (
    facets && (
      <div {...attrs} className={s.root} data-test-sell-facet-filter>
        <div
          className={cx("heading", "small", {
            open: !initialDisplay,
            closed: initialDisplay,
          })}
        >
          <SelectButton
            onClick={toggleListDisplay}
            heading={heading}
            open
            name="facet-filter"
          />
        </div>
        {facetAccordionButton}
        <div
          className={cx("filters", {
            initialDisplay,
            visible: selectedCountSum === 0,
          })}
        >
          <form method="GET" onSubmit={onFormSubmit}>
            <input name="q" type="hidden" value={query} />
            <input name="stp" type="hidden" value={stp} />
            {sortby && <input name="sortby" type="hidden" value={sortby} />}
            <FacetAccordion
              closedFacetFilters={closedFacetFilters}
              facets={facets}
              onClickFacetName={onClickFacetName}
              onToggleFilter={onToggleFilter}
              query={query}
            />
            <div className={s.submitButtonContainer}>
              <Button
                onClick={onToggleListDisplay}
                prio1
                text={buttonText}
                type="button"
              />
            </div>
            {selectedCountSum > 0 ? (
              <div
                data-test-sell-facet-filter-resetbutton
                className={cx("resetButtonContainer", "visible")}
              >
                <LinkButton
                  light
                  prio2
                  icon={<IconX small />}
                  type="reset"
                  onClick={resetFilters}
                >
                  {resetButtonText}
                </LinkButton>
              </div>
            ) : null}
          </form>
        </div>
      </div>
    )
  );
}

FacetFilter.propTypes = {
  facetFilters: PropTypes.object,
  initialDisplay: PropTypes.bool,
  toggleListDisplay: PropTypes.func,
  closeOrOpenFacetFilter: PropTypes.func,
  isClosedFacetFilter: PropTypes.func,
  closedFacetFilters: PropTypes.object,
  url: PropTypes.string,
};

export default FacetFilter;
