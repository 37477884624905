import PropTypes from "prop-types";
import React from "react"; // eslint-disable-line import/no-extraneous-dependencies
import StelarBreadcrumb, {
  SkeletonBreadcrumb,
} from "stelar/components/Breadcrumb";
// import { browserHistory } from "mf-react-router"; // eslint-disable-line import/no-extraneous-dependencies
import classnames from "classnames/bind";
import s from "./Breadcrumb.module.css";

const cx = classnames.bind(s);

function Breadcrumb({
  back,
  crumbs,
  endpoint,
  productCount,
  onLinkClick,
  reduced,
  onClickBack,
}) {
  return (
    <div className={cx("root")} data-test-sell-breadcrumb>
      {!back && !crumbs && !endpoint ? (
        <SkeletonBreadcrumb reduced={reduced} />
      ) : (
        <StelarBreadcrumb
          back={back}
          crumbs={crumbs}
          endpoint={endpoint}
          productCount={productCount}
          onClickLink={onLinkClick}
          onClickBack={onClickBack}
        />
      )}
    </div>
  );
}

Breadcrumb.propTypes = {
  back: PropTypes.string,
  crumbs: PropTypes.array,
  endpoint: PropTypes.string,
  productCount: PropTypes.string,
  onLinkClick: PropTypes.func.isRequired,
  reduced: PropTypes.bool,
  onClickBack: PropTypes.func,
};

Breadcrumb.defaultProps = {
  onClickBack: () => {},
};

export default Breadcrumb;
