// vendor
import React from "react"; // eslint-disable-line import/no-extraneous-dependencies
import PropTypes from "prop-types";
// stelar
import Accordion, { AccordionItem } from "stelar/components/Accordion";
// components
import Bubble from "../../Bubble";
import FacetList from "../FacetList";
import FacetTextList from "../FacetTextList";
// styling
import s from "./Accordion.module.css";

function FacetAccordion({
  closedFacetFilters,
  facets,
  onClickFacetName,
  onToggleFilter,
  query,
}) {
  return (
    <Accordion onChange={onClickFacetName}>
      {facets.map((facet, index) => {
        let openItem;

        if (index >= 1) {
          openItem = closedFacetFilters[facet.name];
          if (closedFacetFilters && Array.from(closedFacetFilters).length > 0) {
            openItem = false;
          }
        } else {
          openItem = !closedFacetFilters[facet.name];
        }

        const bubbleWithCount = (
          <Bubble
            count={facet.selectedCount}
            data-test-sell-facet-filter-bubble
          />
        );

        return (
          <AccordionItem
            light
            iconPosition="right"
            title={
              <div data-test-sell-facet-filter-facet-heading={facet.name}>
                <div className={s.titleContainer}>{facet.headline}</div>
                {bubbleWithCount}
              </div>
            }
            open={openItem}
            key={`facetFilter-${facet.name}`}
            value={facet.name}
            data-test-sell-facet-filter-name={facet.name}
          >
            {facet.name && facet.name !== "size" ? (
              <FacetList
                searchId={query}
                values={facet.values}
                facetName={facet.name}
                onChange={(event) => onToggleFilter(event, facet, facets)}
              />
            ) : (
              <FacetTextList
                searchId={query}
                values={facet.values}
                facetName={facet.name}
                onChange={(event) => onToggleFilter(event, facet, facets)}
              />
            )}
          </AccordionItem>
        );
      })}
    </Accordion>
  );
}

FacetAccordion.propTypes = {
  closedFacetFilters: PropTypes.object,
  facets: PropTypes.array,
  onClickFacetName: PropTypes.func,
  onToggleFilter: PropTypes.func,
  query: PropTypes.string,
};

export default FacetAccordion;
