/* eslint-disable react/no-danger, jsx-a11y/no-static-element-interactions, jsx-a11y/click-events-have-key-events, jsx-a11y/no-noninteractive-element-interactions  */
import PropTypes from "prop-types";
import React from "react"; // eslint-disable-line import/no-extraneous-dependencies

import IconLeft from "stelar/components/Icon/IconLeft";
import IconX from "stelar/components/Icon/IconX";
import FocusTrap from "focus-trap-react";
import { triggerLocationChange } from "shared/utils/customEvents";
import s from "./DrawerContent.module.css";

function DrawerContent({
  drawerId,
  lists,
  parent,
  hideOverlay,
  opened,
  closeButtonText,
}) {
  function preventClick(e) {
    e.stopPropagation();
  }

  const handleBackButton = (e) => {
    e.preventDefault();
    triggerLocationChange(parent?.button?.link || "/");
    hideOverlay();
  };

  const handleLinkClick = (path) => (e) => {
    e.preventDefault();
    triggerLocationChange(path);
    hideOverlay();
  };

  return (
    opened && (
      <FocusTrap
        active={opened}
        focusTrapOptions={{ initialFocus: false, allowOutsideClick: true }}
      >
        <div className={s.root} data-test-stelar-drawer-content>
          <section className={s.overlay} onClick={preventClick}>
            <header className={s.header}>
              {parent?.button && (
                <button
                  type="button"
                  onClick={handleBackButton}
                  aria-label={parent?.button?.label}
                  className={s.backButton}
                  data-test-stelar-overlay-back-button
                >
                  <IconLeft />
                </button>
              )}
              {lists && lists.length > 0 && (
                <div id={`drawerTitle-${drawerId}`} className={s.title}>
                  {lists[0]?.title}
                </div>
              )}
              <button
                type="button"
                onClick={hideOverlay}
                className={s.closeButton}
                aria-label={closeButtonText}
                data-test-stelar-overlay-close-button
              >
                <IconX />
              </button>
            </header>
            <div className={s.content}>
              {lists?.map((navigationList, i) => (
                <>
                  {i !== 0 && (
                    <div
                      className={s.subheader}
                      key={navigationList.title}
                      id={`list-subheader-${i}`}
                    >
                      {navigationList.title}
                    </div>
                  )}
                  <ul
                    className={s.list}
                    aria-labelledby={`list-subheader-${i}`}
                  >
                    {navigationList.naviElements.map(({ name, link }) => (
                      <li key={link}>
                        <a
                          onClick={handleLinkClick(link)}
                          className={s.listItemButton}
                          href={link}
                          role="button"
                          tabIndex="0"
                        >
                          {name}
                        </a>
                      </li>
                    ))}
                  </ul>
                </>
              ))}
            </div>
          </section>
        </div>
      </FocusTrap>
    )
  );
}

DrawerContent.propTypes = {
  drawerId: PropTypes.string.isRequired,
  lists: PropTypes.object.isRequired,
  parent: PropTypes.object,
  opened: PropTypes.bool,
  hideOverlay: PropTypes.func,
  closeButtonText: PropTypes.func,
};

export default DrawerContent;
