import React from "react"; // eslint-disable-line import/no-extraneous-dependencies
import PropTypes from "prop-types";
import Text from "stelar/components/Text";
import classNames from "classnames/bind";
import s from "./FacetList.module.css";

const cx = classNames.bind(s);

function FacetList({ facetName, values, onChange }) {
  return (
    values && (
      <div className={s.root}>
        <div id={`facetTextListId-${facetName}`} className={cx("filters")}>
          {values.map((element) => (
            <div
              data-test-sell-facet-filter-facets
              key={`${facetName}-${element.value}`}
              className={cx(s.filter, {
                activeFilter: element.status === "selected",
              })}
            >
              <label
                htmlFor={`${facetName}-${element.value}`}
                className={cx("labelElement", {
                  disabled: element.status === "inactive",
                })}
              >
                <input
                  type="checkbox"
                  id={`${facetName}-${element.value}`}
                  key={`${facetName}-${element.value}-${new Date().getTime()}`}
                  name={element.value}
                  value={element.value}
                  defaultChecked={element.status === "selected"}
                  disabled={element.status === "inactive"}
                  onChange={onChange}
                  data-facet-filter={facetName}
                  data-facet-value={element.value}
                  className={s.checkbox}
                />
                <span
                  className={cx("label", {
                    disabled: element.status === "inactive",
                  })}
                >
                  <Text small>{element.value}</Text>
                </span>
              </label>
            </div>
          ))}
        </div>
      </div>
    )
  );
}

FacetList.propTypes = {
  facetName: PropTypes.string,
  values: PropTypes.array,
  onChange: PropTypes.func,
};

export default FacetList;
